.app {
  display:flex;
  justify-content: center;
  align-items: center;
  width:100vw;
  height:100vh;  
}
.submit {
  display:block;
  width:100%;
  max-width:300px;
  border-radius:12px;
  background-color:blue;
  color: white;
  padding:16px 20px;
  text-align: center;
  text-decoration: none;
  font-size:1.2rem;
}
